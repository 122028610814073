/* Assuming the root of your HTML (index.html or App.js) has a structure that wraps everything in a div with a class that can be targeted like .page-container */
html, body {
    height: 100%; /* Make sure the body takes the full height of the viewport */
    margin: 0;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Use viewport height to ensure the footer can stick at the bottom */
}

main {
    flex: 1; /* Allows the main content to expand and push the footer downward */
}

/* Footer adjustments */
.footer {
    padding: 1rem 0; /* Reduced padding for a narrower footer */
    background: linear-gradient(135deg, #667eea, #764ba2);
    text-align: center; /* Center the text */
    margin-top: auto; /* This pushes the footer to the bottom */
    font-size: 0.9rem; /* Adjust font size as needed */
}

.footer-link {
    color: #FFD700; /* Adjust link color as needed */
    text-decoration: none; /* No underline by default */
    margin: 0 0.5rem; /* Space between links */
}

.footer-link:hover {
    text-decoration: underline; /* Underline on hover */
}
