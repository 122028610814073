/* Container for the entire tutor card */
.tutor-profile-card {
    flex: 0 0 auto;
    width: 300px;
    max-height: 1000px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: left;
    opacity: 1; /* Make sure opacity is set to 1 */
    transform: translateY(0); /* Remove the initial translate to prevent disappearing */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect to lift the card slightly */
.tutor-profile-card:hover {
    transform: translateY(-20px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Add subtle box shadow on hover */
}

/* Container for the profile image */
.tutor-profile-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.tutor-profile-image {
    width: 250px;
    height: 250px;
    margin-bottom: 5px !important;
    margin: 0 auto 1.5rem;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid transparent;
    padding: 4px;
    object-fit: cover;
}

/* Tutor's name styling */
.tutor-profile-name {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #2c3e50;
    text-align: center;
}

/* General info styling (subject, qualifications, experience, etc.) */
.tutor-profile-subject,
.tutor-profile-qualifications,
.tutor-profile-experience,
.tutor-profile-location,
.tutor-profile-languages,
.tutor-profile-availability {
    margin-bottom: 0.5rem;
    color: #34495e;
}

/* Availability status styling */
.tutor-profile-availability {
    font-weight: bold;
    color: #2c3e50;
}

