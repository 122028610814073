/* PricingSection.css */

.pricing-section {
    padding: 4rem 2rem;
    background-color: #f4f4f4;
    text-align: center;
}

.pricing-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.pricing-description {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 2rem;
    animation: fadeIn 1.5s ease-in-out;
}

.pricing-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    animation: fadeInUp 1.5s ease-in-out;
}

.pricing-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 350px;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
}


.pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.pricing-card-header {
    background: linear-gradient(135deg, #667eea, #764ba2); /* Your theme color */
    color: white;
    padding: 1.5rem;
    text-align: center;
}

.pricing-card-header h3 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: bold;
}

.price {
    font-size: 2rem;
    margin: 0.5rem 0;
}

.pricing-card-body {
    padding: 1.5rem;
    text-align: left;
}

.pricing-info {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
}

.pricing-features {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pricing-features li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
}

.pricing-features .icon {
    margin-right: 0.5rem;
    color: #764ba2;
}

.pricing-card-footer {
    padding: 1rem;
    text-align: center;
    margin-top: auto; /* Ensures the button stays at the bottom */
}


.call-us-btn {
    background: linear-gradient(135deg, #667eea, #764ba2); /* Your theme color */
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
}

.call-us-btn:hover {
    background: linear-gradient(135deg, #5a67d8, #6b46c1); /* Darker shade for hover */
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .pricing-cards-container {
        flex-direction: column;
        align-items: center;
    }
}
