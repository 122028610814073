body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Ensure the entire height is covered */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: 100% !important;
  background-color: #f3f4f6 !important; 
  overflow-x: clip !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1; /* This makes sure the content takes up the remaining space */
}

.footer {
  background-color: #222;
  color: #FFFFFF;
  padding: 1rem 0;
  font-size: 0.9rem;
  text-align: center; /* Ensures the footer content is centered */
}

.footer-link {
  color: #FFD700;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer-link:hover {
  text-decoration: underline;
}
