.about-section {
    padding: 6rem 2rem;
    background-color: #f3f4f6;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    overflow: hidden;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.about-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;
}

.about-text {
    flex: 1 1 50%;
    max-width: 600px;
    position: relative;
    z-index: 2;
    margin: auto;
    align-items: center;
}

.about-title{
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.3s ease, color 0.3s ease;
}



.about-title:hover {
    transform: scale(1.1);
    color: #695ce0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.about-paragraph {
    font-size: 1.25rem;
    line-height: 1.8;
    color: #333;
    padding-right: 20px;
    margin-bottom: 3rem;
}

.features {
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.feature-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(102, 126, 234, 0.1);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.feature-box:hover {
    transform: scale(1.1) translateY(-10px); /* Scale and move the card upwards */
    box-shadow: 0px 30px 60px rgba(118, 75, 162, 0.3); /* Enhanced shadow on hover */
}

.feature-icon {
    font-size: 3.5rem;
    color: #4460dd;
    margin-right: 1.5rem;
    transition: transform 0.3s ease;
}

.feature-box:hover .feature-icon {
    transform: scale(1.3); /* Slightly increase icon size on hover */
}


.feature-box h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem; /* Increased spacing between title and content */
    background: linear-gradient(135deg, #667eea, #764ba2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    margin-right: 15px;
}

.feature-box p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

/* Animations */
@keyframes fadeInUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
    
}@media (max-width: 1400px){
    .feature-box {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .about-text, .features {
        flex: 1 1 100%;
    }
    .feature-box {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1.5rem;
    }
    .features {
        flex-direction: column;
        align-items: center;
    }
    .about-text, .features {
        flex: 1 1 100%;
    }
    .about-text {
        text-align: center;
        margin-bottom: 0;
    }
}



@media (max-width: 1090px){
    .about-content {
        flex-direction: column;
        text-align: center;
    }
    .about-text, .features {
        flex: 1 1 100%;
    }
    .about-text {
        text-align: center;
        margin-bottom: 0;
    }
    .feature-box {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1.5rem;
    }
    .features {
        flex-direction: column;
        align-items: center;

    }
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        text-align: center;
    }

    .about-text, .features {
        flex: 1 1 100%;
    }

    .about-text {
        text-align: center;
        margin-bottom: 0;
    }

    .features {
        flex-direction: column;
        align-items: center;
    }

    .feature-box {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .feature-icon {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .about-paragraph {
        display: none;
        margin: 0;
    }
}
