.top-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    padding: 2px 0; /* Significantly reduced vertical padding */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative; 
    top: 0;
    width: 100%;
    z-index: 1000;
    font-size: 0.85rem; /* Slightly reduced font size */
    line-height: 1; /* Tightened line height */
    height: 50px !important ;
}

.contact-info {
    display: flex;
    align-items: center;
}

.contact-item {
    display: flex;
    align-items: center;
}

.contact-item i {
    margin-right: 5px;
    font-size: 0.9rem; /* Smaller icon size */
}

.call-now-button {
    background: transparent;
    border: 2px solid #f3f4f6;
    padding: 6px 10px; /* Moderate padding for larger button size */
    font-size: 0.85rem; /* Slightly reduced button font size */
    color: #f3f4f6;
    cursor: pointer;
    border-radius: 15px; /* Slightly rounded corners */
    transition: all 0.3s ease;
    text-decoration: none;
}

.call-now-button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    color: white;
    border: 2px solid white;
    transform: scale(1.05);
}
