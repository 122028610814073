.dashboard-container {
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.5s ease;
    max-width: 700px; /* Limit the width of the dashboard content */
    margin: 2rem auto; /* Center the dashboard */
}

.dashboard-welcome {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dashboard-role {
    font-size: 1.3rem;
    color: #635b5b;
    margin-bottom: 1.5rem;
}

.dashboard-content {
    font-size: 1.1rem;
    color: #635e5e;
    text-align: left;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dashboard-message {
    margin-bottom: 1rem;
    line-height: 1.6;
}

/* Animation for card entrance */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
