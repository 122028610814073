.admin-dashboard {
    padding: 2rem;
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-dashboard-header {
    text-align: center;
    margin-bottom: 3rem;
    animation: fadeInDown 1.2s ease;
}

.welcome-text {
    font-size: 3rem;
    color: #667eea;
    margin-bottom: 0.5rem;
    font-weight: 300;
    text-transform: capitalize;
    animation: colorChange 3s infinite;
}

@keyframes colorChange {
    0% { color: #667eea; }
    50% { color: #764ba2; }
    100% { color: #667eea; }
}

.admin-role {
    font-size: 1.2rem;
    color: #764ba2;
    margin-top: 0.5rem;
    animation: fadeIn 1.5s ease;
}

.admin-dashboard-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
    width: 100%;
    max-width: 1200px;
}

.dashboard-card {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    animation: slideInUp 1s ease-out;
}

.dashboard-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.dashboard-card h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    font-weight: 400;
}

.dashboard-card p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2rem;
}

.card-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* Use the button style you provided */
.hero-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    border: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    border-radius: 30px;
    transition: all 0.3s ease;
    animation: fadeInUp 2s ease-out, bounceIn 1.5s ease 0.5s backwards;
    text-align: center;
}

.hero-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
    .admin-dashboard-content {
        grid-template-columns: 1fr;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
