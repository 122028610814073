.dashboard-container {
    padding: 3rem 2rem;
    text-align: center;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-welcome {
    font-size: 3rem;
    color: #333;
    font-weight: 300;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    animation: fadeInDown 1s ease-in-out;
    align-items: center !important;
}

.dashboard-role {
    font-size: 2rem;
    color: #555;
    font-weight: 300;
    margin-bottom: 2rem;
    animation: fadeInDown 1.5s ease-in-out;
}

.dashboard-content {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    color: #555;
    animation: fadeInUp 1.5s ease-in-out;
}

.dashboard-message {
    background: #e6e9f0; /* Light background to keep it subtle */
    color: #333;
    padding: 1.5rem;
    border-radius: 8px;
    display: inline-block;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
