.tutor-request-detail {
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 600px;
    margin: 40px auto;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.detail-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.detail-section p {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #555;
}

.detail-section strong {
    color: #333;
}

.back-btn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 10px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.back-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
}

@media (max-width: 768px) {
    .tutor-request-detail {
        max-width: 90%;
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .detail-title {
        font-size: 1.75rem;
    }

    .detail-section p {
        font-size: 1rem;
    }

    .back-btn {
        font-size: 1rem;
        padding: 8px 16px;
    }
}
