.social-media-section {
    text-align: center;
    padding: 3rem 1rem;
    background-color:  #f3f4f6;
    
}

.social-media-title {
    font-size: 2rem !important;
    margin-bottom: 2rem !important;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent; /* Fallback for older browsers */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.5s ease;
}

.social-media-title:hover {
    transform: scale(1.1);
}

.social-media-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 0;
}

.social-media-link {
    display: inline-block;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    font-size: 2rem;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    line-height: 70px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}

.social-media-link:hover {
    transform: scale(1.15);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

.social-media-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: scale(0);
    border-radius: 50%;
    transition: transform 0.4s ease;
}

.social-media-link:hover::before {
    transform: scale(1.5);
}

.social-media-link svg {
    position: relative;
    z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .social-media-icons {
        gap: 1rem;
    }

    .social-media-link {
        width: 60px;
        height: 60px;
        font-size: 1.8rem;
        line-height: 60px;
    }
}

@media (max-width: 480px) {
    .social-media-title {
        font-size: 2rem;
    }

    .social-media-link {
        width: 55px;
        height: 55px;
        font-size: 1.6rem;
        line-height: 55px;
    }
}
