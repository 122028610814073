.faq-section {
    padding: 2rem 1rem;
    max-width: 80%;
    margin: 0 auto;
}

.faq-title {
    font-size: 2.5rem !important;
    margin-bottom: 4rem !important;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent; /* Fallback for older browsers */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.5s ease;
}

.faq-title:hover {
    transform: scale(1.1);
}

.faq-container {
    margin: 0 auto;
}

.faq-item {
    background-color: white;
    border: 1px solid transparent;
    border-radius: 6px;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
    position: relative;
    overflow: hidden;
}

.faq-item.active {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(118, 75, 162, 0.1);
    border-color: #764ba2;
}
.faq-item.animate {
    opacity: 1;
    transform: translateY(0);
}

.faq-item:hover {
    transform: scale(1.03);
    box-shadow: 0 7px 20px rgba(118, 75, 162, 0.2);
    border-color: #764ba2;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #764ba2; /* Fallback color */
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;
    transition: max-height 0.6s ease, padding 0.6s ease;
    padding: 0 0; /* Initial padding set to 0 to avoid layout shift */
    opacity: 0; /* Initially hidden */
}

.faq-item.active .faq-answer {
    max-height: 1000px; /* Increased max-height to accommodate longer text */
    padding: 0.5rem 0; /* Apply padding when active */
    opacity: 1; /* Make content visible */
    transition: max-height 0.6s ease, padding 0.6s ease, opacity 0.3s ease; /* Smooth transition */
}

.faq-icon {
    font-size: 1.5rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #667eea; /* Fallback color */
}

/* Show More / Show Less Styles */
.show-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.show-more:hover {
    transform: scale(1.05);
    opacity: 0.9;
}

.arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.arrow-text {
    font-size: 1.2rem;
    color: #667eea;
    font-weight: bold;
    margin-bottom: 0.5rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.arrow-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.arrow-icon svg {
    width: 30px;
    height: 30px;
    stroke: #667eea;
}

.arrow-icon.up {
    transform: rotate(180deg);
}

/* Smaller screens adjustments */
@media (max-width: 480px) {
    .faq-section {
        padding: 1.5rem 0.5rem;
        max-width: 90%;
    }

    .faq-title {
        font-size: 1.5rem;
    }

    .faq-question {
        font-size: 1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }

    .arrow-text {
        font-size: 1rem;
    }
}
