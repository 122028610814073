body {
    background-color: #f3f4f6;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    max-width: 100% !important;
}

.contact-page {
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 90%;
    margin: 40px auto;
    gap: 20px;
    
    border-radius: 20px;
    max-width: 100% !important;
}

.contact-info-container, .contact-form-container {
    width: 100% !important;
    padding-left: 20px; /* Ensure consistent padding */
}

.contact-info {
    padding: 20px;
}

.contact-title {
    margin-bottom: 20px;
    align-items: center;
    font-size: 2rem !important;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* This ensures the gradient is shown */
    color: transparent; /* Fallback for older browsers */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.5s ease;
    animation: slideInFromLeft 1s ease-in-out, fadeIn 1.5s ease-in-out;
}

.contact-title:hover {
    transform: scale(1.1);
}

h1 {
    font-size: 1.8rem;
    font-weight: normal;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
}

.contact-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%; /* Ensure items take up the full width */
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.1rem;
    width: 100%; /* Ensure each item takes up the full width */
}

.contact-item p {
    white-space: normal; /* Allow text to wrap to the next line */
    word-wrap: break-word; /* Ensure long words like email addresses break correctly */
    overflow: hidden; /* Prevent overflow */
}

.icon-circle {
    font-size: 1.5rem;
    padding: 10px;
    transition: transform 0.3s ease;
}

.icon-circle:hover {
    transform: rotate(360deg);
}

.contact-form-container {
    background: transparent;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-form-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-row {
    display: flex;
    gap: 20px; /* Adds space between the fields */
}

.form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
}

label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
}

input,
textarea {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background: #f9fafb;
    color: #333;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus {
    border-color: #764ba2;
    box-shadow: 0px 0px 10px rgba(118, 75, 162, 0.3);
}

textarea {
    min-height: 120px;
    resize: vertical;
}

/* Styling for form errors */
.form-error {
    color:  #e74c3c; /* Red color for error messages */
    font-size: 0.875rem;
    margin-top: 0.5rem;
    animation: fadeInUp 0.3s ease-in-out forwards;
}

/* Styling for input fields with errors */
.input-error {
    border-color: #e74c3c; /* Red border color for invalid fields */
    background-color: #ffe6e6;
}

/* Animation for error messages */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.submit-btn {
    padding: 10px 20px !important;
    font-size: 1.2rem !important;
    border-radius: 40px !important;
    min-width: 150px !important;
    background: linear-gradient(135deg, #667eea, #764ba2) !important;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    border: none;
}

.submit-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea) !important;
    transform: translateY(-3px);
}

/* Confirmation modal styling */
.confirmation-overlay {
    background-color: transparent !important;
}

.confirmation-animation {
    text-align: center;
    padding: 1rem;
    background: #28a745;
    color: #fff;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    animation: popUp 0.6s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation-animation .checkmark-circle {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.confirmation-animation .checkmark {
    width: 20px;
    height: 10px;
    border: solid #28a745;
    border-width: 0 0 3px 3px;
    transform: rotate(-45deg);
    animation: checkmarkDraw 0.4s ease-in-out forwards;
}

@keyframes checkmarkDraw {
    0% {
        width: 0;
        height: 0;
    }
    100% {
        width: 20px;
        height: 10px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes popUp {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Responsive Design */
@media (min-width: 1024px) {
    .contact-page {
        flex-direction: row;
        justify-content: space-between;
        max-width: 80%;
    }
}

@media (max-width: 768px) {
    .contact-page {
        max-width: 80%;
    }

    .contact-title,
    .animated-title {
        text-align: center;
    }

    .form-row {
        flex-direction: column; /* Stack First name and Last name vertically on small screens */
        gap: 10px; /* Adjust the gap between fields for small screens */
    }

    .contact-details {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-item {
        font-size: 0.9rem; /* Slightly smaller font on small screens */
    }
}

@media (max-width: 480px) {
    .form-row {
        flex-direction: column; /* Stack First name and Last name vertically on small screens */
        gap: 10px; /* Adjust the gap between fields for small screens */
    }

    .contact-item {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%; /* Ensure items take up full width */
        font-size: 0.9rem; /* Slightly smaller text */
    }

    .icon-circle {
        font-size: 1.2rem; /* Adjust icon size */
        padding: 8px;
    }
}
