.services-section {
    background: #f3f4f6;
    padding: 4rem 2rem;
    color: #333;
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden; /* Prevent vertical scrolling */
}

.section-title {
    font-size: 2.5rem !important;
    margin-bottom: 4rem !important;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.5s ease;
}

.section-title:hover {
    transform: scale(1.1) !important;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.service-card {
    background: linear-gradient(135deg, #7e91e7, #7e6299);
    border-radius: 10px;
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden; /* Prevents card from causing overflow */
}

.service-card:hover {
    transform: translateY(-5px); /* Reduce translateY to avoid pushing content out */
    box-shadow: 0 15px 40px rgba(118, 75, 162, 0.3);
    z-index: 10;
}

.service-card:hover .service-icon {
    transform: rotate(360deg) scale(1.1); /* Reduce scaling to avoid overflow */
    transition: transform 0.7s ease;
}

.service-card:hover .service-title,
.service-card:hover .service-description {
    color: #ffd000;
    transition: color 0.5s ease-in-out;
    transform: scale(1.05);
}

.service-icon {
    font-size: 3rem;
    color: #fff;
    transition: transform 0.5s ease;
}

.service-title {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #fff;
}

.service-description {
    font-size: 1rem;
    line-height: 1.5;
    color: #fff;
}

/* Responsive design */
@media (max-width: 768px) {
    .section-title {
        font-size: 2.5rem;
    }

    .service-icon {
        font-size: 2.5rem;
    }

    .service-title {
        font-size: 1.5rem;
    }

    .service-description {
        font-size: 0.9rem;
    }
}
