/* FindTutor.css */
.find-tutor-container {
    max-width: 1200px;
    margin: 50px auto 0 auto; /* Adjusted bottom margin to 0 */
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 1s ease-in-out;
}

.section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    animation: slideInDown 1s ease-in-out;
}

.search-bar-container {
    position: relative; /* Make it easier to position the icon */
    width: 80%;
    margin: 0 auto 30px;
}

.search-bar {
    width: 100%;
    padding: 10px 15px;
    padding-right: 40px; /* Space for the icon */
    font-size: 1.2rem;
    border-radius: 20px;
    border: 2px solid #667eea;
    transition: all 0.3s ease;
    margin-bottom: 15px !important;
}

.search-bar:focus {
    border-color: #764ba2;
    outline: none;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #667eea;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;

}

.search-icon:hover {
    transform: scale(1.2);
    color: #764ba2;
}

.tutor-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards */
    gap: 20px;
    animation: fadeInUp 0.5s ease-in-out;
}

.tutor-card {
    width: 100%;
    max-width: 300px; /* Control max-width for responsiveness */
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tutor-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.page-item {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.page-item:hover, .page-item.active {
    background: #764ba2;
    transform: scale(1.1);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .tutor-list {
        flex-direction: column;
        align-items: center;
    }

    .tutor-card {
        width: 90%;
        margin-bottom: 20px;
    }

    .search-bar-container {
        width: 90%;
    }
}
