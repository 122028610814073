/* Background outside the section */
body, html {
    background-color: #f3f4f6;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}
.hero-section,
.hero-slide,
.hero-image,
.overlay,
.prev,
.next {
    border-radius: 0 !important; /* Force removal of border radius from all elements */
}

.hero-section {
    width: 100vw; /* Ensure it fills the page horizontally */
    max-width: none;
    height: 750px; /* Default height for smaller screens */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0; /* Remove curved borders */
    overflow: hidden; /* Ensure the image respects the border radius */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
    position: relative;
    background-color: #f3f4f6;
}
/* Ensure other nested elements also follow */
.hero-wrapper {
    border-radius: 0 !important;
}

.hero-slide {
    width: 100%;
    height: 100%;
    border-radius: 0 !important; /* Ensure the slide respects the border radius */
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0 !important; /* Ensure the image follows the container's border radius */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    border-radius: 0 !important; /* Ensure the overlay respects the border radius */
}

.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    border-radius: 0 !important; /* Remove any rounded corners from buttons */
}



.hero-content {
    position: absolute;
    top: 60%; /* Keep text vertically centered */
    left: 60px; /* Align text to the left */
    transform: translateY(-50%);
    color: #f3f4f6;
    text-align: left;
    z-index: 3;
    max-width: 40%; /* Ensure text doesn't cover too much of the image */
    padding: 20px; /* Padding around the text */
    border-radius: 10px; /* Rounded corners for the text background */
    transition: transform 0.3s ease;
}



.hero-title {
    font-size: 2.5rem;
    margin-bottom: 15px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    animation: slideInFromLeft 1s ease-out, zoomIn 2s ease-out;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.hero-subtitle {
    font-size: 1.4rem;
    margin-bottom: 25px;
    line-height: 1.5;
    letter-spacing: 1.2px;
    text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    animation: slideInFromLeft 1s ease-out, zoomIn 2.5s ease-out;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.hero-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    border: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    border-radius: 30px;
    transition: all 0.3s ease;
    animation: fadeInUp 2s ease-out, bounceIn 1.5s ease 0.5s backwards;
    text-align: left;
}

.hero-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    border-radius: inherit; /* Ensure the arrows respect the border radius */
}

.prev {
    left: 15px;
}

.next {
    right: 15px;
}

.prev::before, .next::before {
    content: '';
    display: inline-block;
    width: 35px;
    height: 35px;
    background-size: contain;
}

.prev::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.next::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}


.prev span, .next span {
    display: none; /* Hide any extra arrow text or symbols inside the button */
}

.hero-controls {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 12px;
    z-index: 4;
}

.hero-control-dot {
    width: 20px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-control-dot.active {
    background: rgba(255, 255, 255, 1);
}

.hero-control-dot:hover {
    transform: scale(1.2);
}

/* Text hover effect */
.hero-content:hover .hero-title,
.hero-content:hover .hero-subtitle,
.hero-content:hover .hero-btn {
    transform: scale(1.1); /* Make text bigger on hover */
}

/* Animations */
@keyframes fadeInUp {
    from { opacity: 0; transform: translateY(50px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes zoomIn {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes slideInFromLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInFromRight {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes bounceIn {
    0% { transform: scale(0.5); opacity: 0; }
    50% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); }
}



/* Media query for large screens */
@media (min-width: 1200px) {
    .hero-content {
        left: 15%; /* Move the text further left on large screens */
        max-width: 30%; /* Reduce the width of the text block on large screens */
    }
    .hero-section {
        height: 100vh; /* Increase height for large screens */
    }

    .hero-title {
        font-size: 3rem; /* Increase font size for better readability on large screens */
    }

    .hero-subtitle {
        font-size: 1.5rem; /* Increase font size for better readability on large screens */
    }

}

/* Media query for small screens (keeping the current style) */
@media (max-width: 768px) {
    .hero-content {
        top: 72%; /* Adjust text position for smaller screens */
        left: 40px; /* Adjust the left position for smaller screens */
        max-width: 80%; /* Increase the width on smaller screens */
    }
    .hero-section {
        height: 85vh; /* Increase height for extra-large screens */
    }

    .hero-title {
        font-size: 1.3rem; /* Smaller font size for small screens */
        margin-top: -130px;
    }

    .hero-subtitle {
        font-size: 1rem; /* Smaller font size for small screens */
    }
    .hero-image {
        object-fit: cover; /* Ensures the image covers the entire section */
        object-position: center 10%; /* Crops from the bottom by positioning the top part of the image */
    }
}


/* Media query for large screens (standard desktops) */
@media (min-width: 1024px) {
    .hero-section {
        height: 82.5vh; /* Increase height for large screens */
    }
    .hero-image {
        object-fit: cover; /* Ensures the image covers the entire section */
        object-position: center 20%; /* Crops from the bottom by positioning the top part of the image */
    }
}

/* Media query for extra-large screens (large desktops and small TVs) */
@media (min-width: 1440px) {
    .hero-section {
        height: 95vh; /* Increase height for extra-large screens */
    }
    .hero-image {
        object-fit: cover; /* Ensures the image covers the entire section */
        object-position: center 35%; /* Crops from the bottom by positioning the top part of the image */
    }
}

/* Media query for ultra-large screens (very large desktops and TVs) */
@media (min-width: 1920px) {
    .hero-section {
        height: 100vh; /* Increase height for extra-large screens */
    }
    .hero-image {
        object-fit: cover; /* Ensures the image covers the entire section */
        object-position: center 35%; /* Crops from the bottom by positioning the top part of the image */
    }
}