.manage-tutors-page {
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
}

.manage-tutors-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.manage-tutors-heading {
    font-size: 2.5rem;
    margin: 0;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.add-tutor-button {
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    border-radius: 80px;
    transition: background 0.3s ease, transform 0.3s ease;
    text-decoration: none;
}

.add-tutor-button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

.search-bar-wrapper {
    position: relative;
    width: 80%;
    margin: 20px auto 40px auto;
}

.tutors-search-bar {
    width: 100%;
    padding: 10px 15px;
    padding-right: 40px; /* Space for the icon */
    font-size: 1.2rem;
    border-radius: 20px;
    border: 2px solid #667eea;
    transition: all 0.3s ease;
}

.tutors-search-bar:focus {
    border-color: #764ba2;
    outline: none;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

.tutors-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    justify-content: center;
    padding: 0 2rem;
}

.tutor-card-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 1rem auto;
    width: 100%;
    max-width: 300px;
    text-align: center;
    color: #333;
    display: flex;
    flex-direction: column;
    height: auto;
}

.tutor-card-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.tutor-card-content {
    padding: 20px;
}

.tutor-card-header {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
}

.tutor-card-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: contain;
    margin-top: 0px;
    border-radius: 50%;
}

.tutor-name {
    margin-top: 4rem !important;
    font-size: 1.2rem;
    color: #222;
    margin-bottom: 0.5rem;
}

.tutor-subject-expertise, .tutor-availability {
    font-size: 0.9rem;
    color: #7c7878;
    margin: 0.5rem 0;
}

.tutor-availability {
    font-weight: bold;
    color: #333;
}

.tutor-card-actions {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.edit-tutor-btn, .delete-tutor-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-block;
}

.edit-tutor-btn:hover, .delete-tutor-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: scale(1.1);
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.pagination-btn {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.pagination-btn:hover, .pagination-btn.active {
    background: #764ba2;
    transform: scale(1.1);
}

/* Confirmation modal styling */
.confirmation-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

.confirmation-dialog {
    background-color: white; /* Modal background */
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    color: black;
    animation: slideInUp 0.5s ease-in-out;
}

.confirm-delete-btn, .cancel-delete-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background 0.2s ease, transform 0.2s ease;
    margin: 0 0.5rem;
}

.cancel-delete-btn {
    background: #e74c3c;
}

.confirm-delete-btn:hover, .cancel-delete-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

.confirmation-checkmark-circle {
    color: #28a745; /* Green checkmark */
    margin-bottom: 1.5rem;
    animation: popIn 0.6s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
