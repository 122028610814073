body {
    background-color: #f3f4f6;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
}

.add-tutor-page {
    display: flex;
    justify-content: center;
    padding: 40px;
    max-width: 90%;
    margin: 40px auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #ffffff;
}

.add-tutor-form-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
}

.add-tutor-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #333;
}

input, textarea {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background: #f9fafb;
    color: #333;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus, textarea:focus {
    border-color: #764ba2;
    box-shadow: 0px 0px 10px rgba(118, 75, 162, 0.3);
}

textarea {
    min-height: 120px;
    resize: vertical;
}

.submit-btn {
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 40px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    border: none;
    display: block;
    margin: 0 auto;
}

.submit-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

/* Styling for form errors */
.form-error {
    color: #e74c3c; /* Red color for error messages */
    font-size: 0.875rem;
    margin-top: 0.5rem;
    animation: fadeInUp 0.3s ease-in-out forwards;
}

/* Styling for input fields with errors */
.input-error {
    border-color: #e74c3c; /* Red border color for invalid fields */
    background-color: #ffe6e6;
}

/* Confirmation modal styling */
.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

.confirmation-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    animation: slideInUp 0.5s ease-in-out;
}

.confirmation-title {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.5rem;
}
/* Styling for image upload input */
.image-upload {
    display: block;
    margin-top: 5px;
    padding: 0; /* Remove default padding */
    border: none; /* Remove border */
    background-color: transparent; /* Set background to transparent */
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
}

/* Custom style for the file select button */
.image-upload::file-selector-button {
    padding: 0.7rem 1.5rem; /* Smaller padding */
    background: linear-gradient(135deg, #667eea, #764ba2); /* Match button gradient */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem; /* Adjust font size */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Add slight shadow like the Add Tutor button */
}

/* Hover effect for the image upload button */
.image-upload::file-selector-button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea); /* Hover gradient change */
    transform: translateY(-2px); /* Lift effect */
}

/* Focus state for the image upload button */
.image-upload::file-selector-button:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 4px rgba(102, 126, 234, 0.4); /* Custom focus ring */
}

/* Dropdown (select) styling */
.auth-select {
    padding: 0.8rem; /* Match padding of input fields */
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem; /* Match font size of input fields */
    width: 100%;
    transition: border-color 0.3s ease, background-color 0.3s ease; /* Smooth transition for background color */
    appearance: none; /* Remove default browser styles */
    background-color: #f0f4f8; /* Match the input background color */
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23667eea" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1rem center; /* Position the arrow */
    background-size: 16px 16px; /* Size of the arrow */
}

/* General styling for the dropdown */
/* Styling for the select field */
select {
    padding: 0.7rem 1.2rem;  /* Padding for a clean feel */
    border: 1px solid #ccc;  /* Light border to match other form fields */
    border-radius: 8px;  /* Rounded corners */
    font-size: 1rem;  /* Standard font size */
    width: 100%;  /* Full width */
    appearance: none;  /* Remove default browser styling */
    background-color: #f8f9fa;  /* Light background color for consistency */
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23667eea" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');  /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 1.2rem center;  /* Position the arrow on the right */
    background-size: 16px;  /* Adjust the size of the arrow */
    transition: border-color 0.3s ease, background-color 0.3s ease;  /* Smooth transitions */
}

/* Focus state for the select field */
select:focus {
    border-color: #667eea;  /* Theme color for the border on focus */
    background-color: #eef2ff;  /* Light blue background on focus */
    outline: none;  /* Remove the default focus outline */
}

/* Invalid state styling for the select field */
select.input-error {
    border-color: #e74c3c;  /* Red border color for invalid fields */
    background-color: #ffe6e6;  /* Light red background for invalid state */
}

/* Hover and focus states for invalid dropdown */
select.input-error:hover,
select.input-error:focus {
    border-color: #e74c3c;  /* Red border for invalid state */
    background-color: #ffe6e6;  /* Light red background for invalid state */
}

/* Error message styling */
.auth-error {
    color: #e74c3c; /* Red color for error messages */
    font-size: 0.875rem; /* Same font size as your provided code */
    opacity: 0;
    animation: fadeInUp 0.8s ease-in-out forwards;
}

/* Keyframes for error message fade-in */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
