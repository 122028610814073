.add-user-page {
    display: flex;
    justify-content: center;
    padding: 40px;
    max-width: 90%;
    margin: 40px auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #ffffff;
}

.add-user-form-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
}

.add-user-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #333;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background: #f9fafb;
    color: #333;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

textarea {
    min-height: 120px;
    resize: vertical;
}

/* Radio Button Styles */
.role-selection {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.role-option {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
}

.role-option input {
    margin-right: 10px;
    accent-color: #667eea;
    transform: scale(1.2);
}

.role-option input:checked + span {
    color: #764ba2;
}

.submit-btn {
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 40px;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    border: none;
    display: block;
    margin: 20px auto 0 auto;
}

.submit-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

/* Styling for form errors */
.form-error {
    color: #e74c3c;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    animation: fadeInUp 0.3s ease-in-out forwards;
}

/* Styling for input fields with errors */
.input-error {
    border-color: #e74c3c;
    background-color: #ffe6e6;
}

/* Confirmation modal styling */
.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

.confirmation-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    animation: slideInUp 0.5s ease-in-out;
}

.confirmation-title {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.5rem;
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
