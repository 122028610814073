.tutor-application-detail {
    max-width: 900px;
    margin: 50px auto;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    text-align: left;
    animation: fadeInUp 0.5s ease-in-out;
}

.application-header {
    display: flex;
    justify-content:  space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.application-header h2 {
    font-size: 2rem;
    color: #333;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 30vh;
}

.back-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    border: none;
    transition: background 0.3s ease, transform 0.3s ease;

}

.back-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

.application-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.application-section {
    background: #f9fafb;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.application-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.application-section p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
}

.application-section p a {
    color: #667eea;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.application-section p a:hover {
    color: #764ba2;
}

.application-section:hover {
    transform: translateY(-5px);
}

.loading,
.error-message {
    text-align: center;
    font-size: 1.5rem;
    color: #e74c3c;
    padding: 2rem;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
