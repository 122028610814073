body {
    background-color: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

/* Error styles matching the ContactForm */
.input-error {
    border-color:  #df8e8e!important; /* Red border color for invalid fields */
    background-color: #ffe6e6; /* Light red background for inputs with errors */
}

.error-text {
    color:  #e74c3c; /* Red color for error messages */
    font-size: 0.875rem; /* Slightly smaller font for error text */
    animation: fadeInUp 0.3s ease-in-out forwards;
    text-align: left; /* Align error text to the left */
}

/* Animation for error messages */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.become-tutor {
    background-color: #ffffff;
    padding: 3rem 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    margin: 50px auto;
    text-align: center;
    transition: padding 0.3s ease, max-width 0.3s ease;
}

.tutor-title {
    font-size: 2.5rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: 2rem;
    animation: fadeIn 1s ease-in-out;
    transition: font-size 0.3s ease;
}

.step-progress {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    position: relative;
    flex-wrap: wrap;
}

.step-item {
    text-align: center;
    flex: 1;
    position: relative;
    padding: 1rem;
}

.step-item .step-number {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin: 0 auto;
    transition: background 0.3s ease, transform 0.3s ease;
}

.step-item .step-label {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    color: transparent;
    transition: font-size 0.3s ease;
}

.step-item.completed .step-number {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    transform: scale(1.1);
}

.step-item.current .step-number {
    background: linear-gradient(135deg, #764ba2, #667eea);
    color: #fff;
    animation: pulse 1s infinite;
}

.form-step select {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    background-color: #fff;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-step select:focus {
    border-color: #764ba2;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

.form-step textarea {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    resize: vertical;
    min-height: 200px;
}

.form-step textarea:focus {
    border-color: #764ba2;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.tutor-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-step {
    margin-bottom: 2rem;
    width: 100%;
}

.form-step input[type="text"],
.form-step input[type="email"],
.form-step textarea,
.form-step input[type="file"] {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-step input[type="text"]:focus,
.form-step input[type="email"]:focus,
.form-step textarea:focus,
.form-step input[type="file"]:focus {
    border-color: #764ba2;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

.form-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 1.5rem;
}

.form-navigation .next-btn {
    margin-left: auto; /* Push the Next button to the right */
}

.form-navigation .prev-btn {
    margin-right: auto; /* Push the Previous button to the left */
}

.form-navigation button,
.submit-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    font-size: 1.2rem;
}

.submit-btn {
    padding: 1rem 2rem !important;
    border-radius: 10px !important;
    font-size: 1rem !important;
}

.form-navigation button:hover,
.submit-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

.submit-btn {
    background: linear-gradient(135deg, #28a745, #218838); /* Green for the submit button */
    color: #fff;
}

.submit-btn:hover {
    background: linear-gradient(135deg, #218838, #28a745); /* Darker shade on hover */
}

.review-item {
    align-items: right !important;
}

.confirmation-animation {
    text-align: center;
    padding: 1.5rem;
    background-color: rgb(245, 245, 245);
    color: #465cc2;
    border-radius: 10px;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    animation: popUp 0.6s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation-animation .checkmark-circle {
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.confirmation-animation .checkmark {
    width: 25px;
    height: 12px;
    border: solid #28a745;
    border-width: 0 0 3px 3px;
    transform: rotate(-45deg);
    animation: checkmarkDraw 0.4s ease-in-out forwards;
}

@keyframes checkmarkDraw {
    0% {
        width: 0;
        height: 0;
    }
    100% {
        width: 25px;
        height: 12px;
    }
}

@keyframes popUp {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.review-step .review-section {
    text-align: left !important; /* Align text to the left */
    padding-left: 40%; /* Add padding to the left */
}

.review-step .review-item {
    margin-bottom: 15px; /* Add some space between review items */
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .become-tutor {
        max-width: 90%;
        padding: 2rem;
    }

    .tutor-title {
        font-size: 2.25rem;
    }

    .form-navigation button {
        padding: 1rem 1.5rem;
        font-size: 1rem;
    }

    .submit-btn {
        padding: 1rem 1.5rem;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .become-tutor {
        max-width: 95%;
        padding: 1.5rem;
    }

    .tutor-title {
        font-size: 2rem;
    }

    .form-navigation button {
        width: 100%;
        margin-top: 1rem;
    }

    .submit-btn {
        width: 100%;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .become-tutor {
        padding: 1rem;
    }

    .tutor-title {
        font-size: 1.75rem;
    }

    .form-step input[type="text"],
    .form-step input[type="email"],
    .form-step textarea,
    .form-step input[type="file"] {
        padding: 0.75rem;
    }

    .form-navigation button {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
    }

    .submit-btn {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
    }
}
