.tutor-applications {
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
}

.applications-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.applications-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem; /* Spacing between cards */
}

.application-card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    width: 350px;
    padding: 1.5rem;
    text-align: left;
    animation: fadeInUp 0.5s ease;
}

.application-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.application-header {
    margin-bottom: 0.5rem; /* Reduced spacing to match the other cards */
}

.applicant-info {
    font-size: 1.1rem;
    color: #635b5b;
    margin: 0; /* Remove margin for compact spacing */
}

.applicant-name {
    font-weight: bold;
    margin-bottom: 0.2rem; /* Ensure title has a little space from value */
}

.applicant-phone {
    font-weight: bold;
    margin-bottom: 0.2rem; /* Ensure title has a little space from value */
}

.application-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
}

.hero-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    border: none;
}

.hero-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

/* Animation for card entrance */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
