.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #f3f4f6; /* Background color to match the theme */
}

.reset-password-form {
    background-color: white;
    padding: 2rem; /* Slightly reduced padding for consistency */
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Softer shadow */
    max-width: 450px; /* Increased width slightly for better space */
    width: 100%;
    text-align: center;
    animation: fadeInUp 0.5s ease-in-out; /* Smooth fade-in effect */
    margin-bottom: 5vh; /* To ensure spacing from the footer if there is one */
}

.reset-password-field {
    margin-bottom: 20px;
    text-align: left;
}

.reset-password-field label {
    display: block;
    font-size: 1rem;
    color: #333;
}

.reset-password-field input {
    width: 100%;
    padding: 0.8rem; /* Reduced padding for a consistent look */
    border: 1px solid #ccc;
    border-radius: 8px; /* Increased border-radius to match theme */
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
}

.reset-password-field input:focus {
    border-color: #667eea; /* Theme color for focus state */
    box-shadow: 0 0 5px rgba(118, 75, 162, 0.2); /* Subtle shadow on focus */
}

.reset-password-button {
    width: 100%;
    padding: 0.9rem; /* Reduced padding slightly */
    background: linear-gradient(135deg, #667eea, #764ba2); /* Updated gradient */
    color: white;
    border: none;
    border-radius: 8px; /* Match border-radius with input fields */
    font-size: 1rem; /* Slightly reduced font-size */
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    margin-top: 1.5rem; /* Add space before button */
    align-items: center !important;
}

.reset-password-button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea); /* Hover gradient */
    transform: translateY(-3px); /* Lift effect on hover */
}

.error-message {
    color: #e74c3c;;
    margin-top: -20px;
    margin-bottom: -40px;
    font-size: 0.9rem; /* Reduced font-size for subtle error messages */
    text-align: center;
}

.success-message {
    color: #28a745;
    margin-bottom: 15px;
    font-size: 0.9rem; /* Reduced font-size for consistency */
    text-align: left;
}

.reset-password-link {
    margin-top: 15px;
    color: #667eea;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
}

.reset-password-field {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.password-toggle-icon2 {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2rem;
    color: #667eea; /* Theme color */
}

.input-with-icon input {
    padding-right: 30px; /* Add padding to prevent text from hiding behind icon */
}


/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
