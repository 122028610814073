/* TestimonialsSection.css */
.testimonials-section {
    padding: 60px 20px;
    background-color: #f5f5f5;
    text-align: center;
    position: relative;
    overflow: hidden;
    animation: fadeIn 1.5s ease-in-out;
}

.section-title {
    font-size: 3rem;  /* Bigger title for large screens */
    margin-bottom: 40px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    z-index: 1;
    animation: fadeInDown 1s ease-in-out;
}

.testimonials-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%; /* Ensure it doesn't exceed the viewport */
    margin: 0 auto;
    padding: 0 20px; /* Adjust padding to avoid overflow */
    overflow: hidden; /* Add overflow hidden to container */
}


.testimonial {
    flex: 1 0 auto; /* Allow the testimonial text to take up available space */
    max-width: 100%;
    margin: 0 40px;  /* Add more space between arrows and text */
    position: relative;
    z-index: 1;
    opacity: 0;  /* Initial opacity for animation */
    animation: fadeIn 1s ease-in-out forwards;  /* Fade in animation */
    overflow-x: hidden;
}

.testimonial-text {
    font-size: 1.8rem;  /* Bigger text for large screens */
    font-style: italic;
    color: #555;
    line-height: 1.8;
    animation: slideInUp 1.2s ease-in-out forwards;  /* Slide in animation */
}

/* Styles for the slider-control arrows */
.slider-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    color: #333; /* Explicitly set arrow color */
    transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effect to highlight the arrow on hover */
.slider-control:hover {
    color: #764ba2; /* Hover color matching your theme */
    transform: scale(1.2); /* Increase size on hover */
}

/* Adjust the position of the left arrow */
.slider-control.prev {
    left: 20px;
}

/* Adjust the position of the right arrow */
.slider-control.next {
    right: 20px;
}


/* Dots default style */
.dots {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
    animation: fadeInUp 1.2s ease-in-out;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}

.dot.active {
    background-color: #333;
}

.slider-control::before,
.slider-control::after {
    content: none; /* Remove any default content like the arrow */
}
/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInDown {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInLeft {
    from { opacity: 0; transform: translateX(-20px); }
    to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInRight {
    from { opacity: 0; transform: translateX(20px); }
    to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes slideInUp {
    from { opacity: 0; transform: translateY(40px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .section-title {
        font-size: 2.5rem;
    }

    .testimonial-text {
        font-size: 1.6rem;
    }

    .testimonials-container {
        max-width: 900px;
        padding: 0 50px;
    }

    .slider-control {
        font-size: 2.5rem;
    }

    .testimonial {
        margin: 0 30px;
    }
}

@media (max-width: 992px) {
    .section-title {
        font-size: 2.2rem;
    }

    .testimonial-text {
        font-size: 1.4rem;
    }

    .testimonials-container {
        max-width: 800px;
        padding: 0 40px;
    }

    .slider-control {
        font-size: 2.2rem;
    }

    .testimonial {
        margin: 0 25px;
    }
}
/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .slider-control {
        font-size: 1.5rem; /* Smaller font size for arrows */
        top: 55%; /* Move arrows lower for better positioning */
    }

    /* Adjust the position of left and right arrows */
    .slider-control.prev {
        left: 10px; /* Closer to the edge on small screens */
    }

    .slider-control.next {
        right: 10px; /* Closer to the edge on small screens */
    }

    /* Adjust testimonial container and text */
    .testimonials-container {
        padding: 0 20px; /* Reduce padding for smaller screens */
    }

    .testimonial-text {
        font-size: 1.1rem; /* Smaller text size for testimonials */
    }
    .dot {
        height: 10px;
        width: 10px;
    }
}

/* Further adjustments for extra-small screens */
@media (max-width: 480px) {
    .slider-control {
        font-size: 1.2rem; /* Even smaller font size for arrows */
    }

    /* Position the arrows for better fit on extra-small screens */
    .slider-control.prev {
        left: 5px;
    }

    .slider-control.next {
        right: 5px;
    }

    .testimonial-text {
        font-size: 1rem; /* Further reduce text size */
    }

    .testimonials-container {
        padding: 0 15px; /* Reduce padding more on very small screens */
    }
    .dot {
        height: 8px;
        width: 8px;
    }

    .dots {
        gap: 8px; /* Reduce the gap between dots for extra small screens */
    }
}