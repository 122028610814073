.manage-users {
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
}

.manage-users-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.manage-users-title {
    font-size: 2.5rem;
    margin: 0;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.add-user-btn {
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    border-radius: 8px;
    transition: background 0.3s ease, transform 0.3s ease;
    text-decoration: none;
}

.add-user-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

.search-bar-container {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.search-bar {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #667eea;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    padding-right: 40px;
}

.search-bar:focus {
    border-color: #764ba2;
    outline: none;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #667eea;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
}

.search-icon:hover {
    transform: scale(1.2);
    color: #764ba2;
}

.user-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    justify-content: center;
    padding: 0 1rem;
}

.user-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0.5rem auto;
    width: 100%;
    max-width: 250px;
    text-align: center;
    color: #333;
    display: flex;
    flex-direction: column;
    height: auto;
}

.user-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.user-card-body {
    padding: 15px;
}

.user-card-header {
    position: relative;
    overflow: hidden;
    padding-top: 80%;
}

.user-card-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: 80%;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.user-name {
    margin-top: 1rem;
    font-size: 1rem;
    color: #222;
    margin-bottom: 0.3rem;
}

.user-email, .user-phone, .user-role {
    font-size: 0.8rem;
    color: #7c7878;
    margin: 0.3rem 0;
}

.user-actions {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem;
    position: relative;
}

.role-change-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: 2px solid #667eea;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.role-change-btn:hover,
.role-change-btn:focus {
    border-color: #764ba2;
    outline: none;
    box-shadow: 0 0 10px rgba(118, 75, 162, 0.3);
}

/* Confirmation Modal Styles */
.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 1000;
}

.confirmation-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    animation: slideInUp 0.5s ease-in-out;
}

.confirmation-title {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.5rem;
}

.role-selection {
    margin-bottom: 1.5rem;
    text-align: left;
}

.role-selection label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
}

.confirmation-button {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background 0.2s ease, transform 0.2s ease;
    margin: 0 0.5rem;
}

.confirmation-button.cancel-button {
    background: #e74c3c;
}

.confirmation-button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
