.featured-tutors-section {
  padding: 2rem 0;
  background-color: #f3f4f6;
  text-align: center;
  position: relative;
  margin-right: 15px !important;
}

.featured-tutors-title {
  font-size: 2.5rem !important;
  margin-bottom: 4rem !important;
  background: linear-gradient(135deg, #667eea, #764ba2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback for older browsers */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.5s ease;
}

.featured-tutors-scroll-container {
  width: 100%;
  overflow-x: auto;
  padding: 2rem 0;
  position: relative;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.featured-tutors-cards-wrapper {
  display: flex;
  gap: 1.5rem;
  padding: 0 3rem;
}

.featured-tutor-card {
  flex: 0 0 auto;
  width: 300px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: left;
  transform: translateY(0); /* Make sure cards are visible initially */
  opacity: 1; /* Make sure the card is visible */
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.featured-tutor-card.tutor-card-animate {
  opacity: 1;
  transform: translateY(0);
}

.featured-tutor-card:hover {
  transform: translateY(-25px);
}

.featured-tutor-image-container {
  width: 250px;
  height: 250px; /* Ensure the container is a square */
  margin: 0 auto 1.5rem;
  border-radius: 50%; /* Make sure the image container is circular */
  overflow: hidden;
  border: 4px solid transparent;
  padding: 4px;
}

.featured-tutor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; /* Ensure the image itself is circular */
}

.featured-tutor-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.featured-tutor-card p {
  margin-bottom: 0.5rem;
  color: #34495e;
}

/* Arrow button styles */
.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 4rem;
  color: #764ba2;
  cursor: pointer;
  z-index: 10;
}

.scroll-arrow:hover {
  color: #667eea;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

