.dashboard-container {
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
}

.dashboard-welcome {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dashboard-role {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 2rem;
}

.dashboard-content {
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.5s ease;
}

.dashboard-message {
    font-size: 1.2rem;
    color: #635b5b;
    margin: 0;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
