.tutor-requests {
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
}

.requests-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    background: linear-gradient(135deg, #667eea, #764ba2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.requests-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem; /* Spacing between cards */
}

.request-card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    width: 350px;
    padding: 1.5rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: fadeInUp 0.5s ease;
}

.request-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.request-header {
    margin-bottom: 1.5rem;
}

.request-name {
    margin-top: 0.5rem;
    font-size: 1.1rem;
    color: #635e5e;
    margin-bottom: 1%; /* Space below name */
    display: block;
}
.request-date {
    color: #635e5e;
    font-size: 1rem;
    margin: 0.5rem 0;
    line-height: 1.5;
}


.request-email, .request-phone {
    font-size: 1.1rem;
    color: #635e5e;
    margin-top: 0.5rem;
}

.request-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
}

.hero-btn {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    border: none;
}

.hero-btn:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
    transform: translateY(-3px);
}

/* Animation for card entrance */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
